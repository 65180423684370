import { PAPER_TYPE } from "@/consts";
import { mapState } from 'vuex';

export default {
  data () {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapState({
      directory: ({ realQuestion }) => realQuestion.directory
    })
  },
  methods: {
    queryDirectory () {
      const directoryMap = {
        [PAPER_TYPE.REAL_QUESTION]: this.realQuestionDirectory,
        [PAPER_TYPE.REFACTOR_REAL]: this.refactorRealDirectory,
        [PAPER_TYPE.REFACTOR_TOPIC]: this.refactorTopicDirectory,
        [PAPER_TYPE.COLLECTION]: this.collectionDirectory
      }
      const { type } = this.routeParams
      directoryMap[type] && directoryMap[type]()
    },
    // 查询真题目录
    async realQuestionDirectory (param) {
      try {
        const { mathType } = this.routeParams || {}
        const ret = await this.$api.queryRealQuestionDirectory({
          param: {
            math_type: mathType,
            catalog_id: 1,
            ...param,
          }, method: 'get'});
        this.$store.commit('realQuestion/updateDirectory', {
          directory: {...ret.maths[0].books[0]} || { chapters: [] }
        })
      } catch (err) {
        console.error(err)
      } finally {
        this.loading = false;
        this.queryLoading = false
      }
    },
    // 查询重组真题目录
    async refactorRealDirectory () {
      try {
        const ret = await this.$api.queryRefactorDirectory({
          param: {
            catalog_id: 3
          }
        });
        this.$store.commit('realQuestion/updateDirectory', {
          directory: {...ret.zujuan.maths[0].books[0]} || { chapters: [] }
        })
      } catch (err) {
        console.error(err)
      } finally {
        this.queryLoading = false;
      }
    },
    // 查询专题目录
    async refactorTopicDirectory () {
      try {
        const routerParams = this.$route.query
        const { mathType, book } = routerParams
        const { maths = [] } = await this.$api.queryRefactorTopicDirectory({});
        const [curBook] = maths.filter(i => i.mathType === mathType)
        this.$store.commit('realQuestion/updateDirectory', {
          directory: {...curBook.books.filter(i => i.bookID === book)[0]} || { chapters: [] }
        })
      } catch (err) {
        console.error(err)
      } finally {
        this.queryLoading = false;
      }
    },
    // 收藏目录查询
    async collectionDirectory () {
      try {
        const ret = await this.$api.queryCollectionDirectory({
          catalog_id: 4
        });
        this.$store.commit('realQuestion/updateDirectory', {
          directory: {...ret.maths[0].books[0]} || { chapters: [] }
        })
      } catch (err) {
        console.error(err)
      } finally {
        this.queryLoading = false;
      }
    }
  }
}
