var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"desc"},[_vm._v(" 按最新大纲和题型预测重组历年真题，适合冲刺阶段 ")]),_c('div',{staticClass:"form"},[_c('span',{on:{"click":function($event){_vm.levelVisible = true}}},[_c('van-button',{attrs:{"icon":"arrow-down","type":"primary","icon-position":"right","size":"small"}},[_vm._v(" "+_vm._s(_vm.levelLabel)+" ")])],1),_c('span',{on:{"click":function($event){_vm.mathTypeVisible = true}}},[_c('van-button',{attrs:{"icon":"arrow-down","type":"primary","icon-position":"right","size":"small"}},[_vm._v(" "+_vm._s(_vm.mathTypeLabel)+" ")])],1),_c('span',{on:{"click":_vm.handleVisibleYear}},[_c('van-button',{attrs:{"icon":"arrow-down","type":"primary","icon-position":"right","size":"small"}},[_vm._v(" 已选"+_vm._s(_vm.form.year.length)+"年 ")])],1)]),_c('div',{staticClass:"tools"},[(!_vm.hasPaper)?_c('div',{staticClass:"create-btn",on:{"click":_vm.handleCreatePaper}},[_vm._v(" 开始组卷 ")]):_c('div',{staticClass:"continue-btn"},[_c('div',{on:{"click":_vm.handleContinue}},[_vm._v("继续做题")]),_c('div',{on:{"click":_vm.handleCreatePaper}},[_vm._v("重新组卷")])])]),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.levelVisible),callback:function ($$v) {_vm.levelVisible=$$v},expression:"levelVisible"}},[_c('van-picker',{attrs:{"title":"选择模式","show-toolbar":"","columns":_vm.REFACTOR_MODE,"value-key":"label","default-index":_vm.form.level},on:{"confirm":function (ref) {
	var value = ref.value;

	return _vm.onConfirm(value, 'level');
},"cancel":function($event){_vm.levelVisible = false}}})],1),_c('van-popup',{style:({ height: '308px' }),attrs:{"position":"bottom"},model:{value:(_vm.yearVisible),callback:function ($$v) {_vm.yearVisible=$$v},expression:"yearVisible"}},[_c('div',{staticClass:"year-select"},[_c('div',{staticClass:"header"},[_c('div',{on:{"click":_vm.handleCancelYearSelect}},[_vm._v("取消")]),_c('p',[_vm._v("选择范围")]),_c('div',{on:{"click":_vm.handleConfirmYearSelect}},[_vm._v("确定")])]),_c('div',{staticClass:"years"},_vm._l((_vm.computedYears),function(year){return _c('div',{key:year,class:_vm.activeYears.includes(year) ? 'active' : '',on:{"click":function($event){return _vm.handleSelectYear(year)}}},[_vm._v(" "+_vm._s(year)+" ")])}),0)])]),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.mathTypeVisible),callback:function ($$v) {_vm.mathTypeVisible=$$v},expression:"mathTypeVisible"}},[_c('van-picker',{attrs:{"title":"选择科目","show-toolbar":"","columns":_vm.MATH_TYPE,"value-key":"label","default-index":0},on:{"confirm":function (ref) {
	var value = ref.value;

	return _vm.onConfirm(value, 'mathType');
},"cancel":function($event){_vm.levelVisible = false}}})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('div'),_c('p',[_vm._v("真题重组测验")])])}]

export { render, staticRenderFns }