<template>
  <div class="card">
    <div class="title">
      <div/>
      <p>专题重组训练</p>
    </div>

    <div class="desc">
      按专题重组，支持跨学科整合，适合针对性训练
    </div>

    <div class="form">
      <div
          v-for="(item, index) in MATH_TYPE"
          :key="item.value"
          @click="handleChange(item)"
          :class="item.value === activeMathType ? 'active' : ''"
      >
        {{ item.label }}
      </div>
    </div>

    <div class="books" v-if="directory.length > 0">
      <van-tabs v-model="activeBook">
        <van-tab v-for="book in directory" :title="book.bookName" :name="book.bookID" :key="book.bookID">
          <div v-for="(chapter, chapterIndex) in book.chapters" :key="chapter.chapterId" class="chapters">
            <van-collapse :border="false" v-model="activeChapter">
              <van-collapse-item :title="chapter.chapterName" :name="chapter.chapterName">
                <div
                    v-for="(section, index) in chapter.sections"
                    :key="section.sectionName"
                    @click="handleSection(section)"
                >
                  <section-card
                      show-question-nums
                      :index="index + 1"
                      :section="section"
                      :isActive="activeSection === section.sectionName"
                      @question-click="({question, questionIdx}) => handleQuestionClick(chapterIndex, index, questionIdx, question)"
                  />
                </div>
              </van-collapse-item>
            </van-collapse>
          </div>
        </van-tab>
      </van-tabs>
    </div>

    <van-overlay :show="loading">
      <div class="wapper">
        <van-loading type="circular" vertical>
          加载中...
        </van-loading>
      </div>
    </van-overlay>
  </div>
</template>

<script type="application/javascript">
import { Toast } from 'vant'
import SectionCard from '@/components/SectionCard';
import { MATH_TYPE, MATH_TYPE_ENUM, PAPER_TYPE } from '@/consts';

export default {
  components: {SectionCard},
  data () {
    return {
      loading: true,
      MATH_TYPE,
      activeMathType: MATH_TYPE_ENUM.MATH_ONE,
      activeBook: 'gaoshu',
      activeChapter: [],
      activeSection: '',
      mathTypeDirectory: {
        [ MATH_TYPE_ENUM.MATH_ONE ]: {},
        [ MATH_TYPE_ENUM.MATH_TWO ]: {},
        [ MATH_TYPE_ENUM.MATH_THREE ]: {},
        [ MATH_TYPE_ENUM.MATH_ALL ]: {},
      }
    }
  },
  computed: {
    directory () {

      return this.mathTypeDirectory[ this.activeMathType ].books || []
    }
  },
  methods: {
    async handleChange (item) {
      if (!this.mathTypeDirectory[ item.value ].is_unlock) {
        const toastMap = {
          [MATH_TYPE_ENUM.MATH_ONE]: '请先购买数学一',
          [MATH_TYPE_ENUM.MATH_TWO]: '请先购买数学二',
          [MATH_TYPE_ENUM.MATH_THREE]: '请先购买数学三',
          [MATH_TYPE_ENUM.MATH_ALL]: '请先购买数学一、数学二、数学三',
        }
        Toast({
          message: toastMap[item.value],
          icon: 'info-o',
        })
        return;
      }
      this.activeMathType = item.value;
    },

    async queryMathTypeDirectory () {
      this.loading = true
      try {
        const ret = await this.$api.queryRefactorTopicDirectory({});
        this.mathTypeDirectory = {
          [ MATH_TYPE_ENUM.MATH_ONE ]: ret.maths[ 0 ] || {},
          [ MATH_TYPE_ENUM.MATH_TWO ]: ret.maths[ 1 ] || {},
          [ MATH_TYPE_ENUM.MATH_THREE ]: ret.maths[ 2 ] || {},
          [ MATH_TYPE_ENUM.MATH_ALL ]: ret.maths[ 3 ] || {}
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false
      }
    },

    // 选择节
    handleSection (item) {
      this.activeSection = item.sectionName
    },

    handleQuestionClick (chapterIndex, sectionIndex, questionIdx, question) {
      this.$router.push({
        path: '/question',
        query: {
          chapterIndex,
          sectionIndex,
          questionIdx,
          questionID: question.questionID,
          type: PAPER_TYPE.REFACTOR_TOPIC,
          mathType: this.activeMathType,
          book: this.activeBook
        }
      })
    },

    // 查询专题组卷进度
    async getProcess (param) {
      try {
        const ret = await this.$api.queryQuestionResult({param});
        console.log(ret)
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted () {
    this.getProcess({
      catalog_id: 2
    })
    this.queryMathTypeDirectory()
  }
}
</script>

<style lang="less" scoped>
@import './index.less';

.form {
  padding: 0 20px 16px 20px;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;

  .active {
    border-color: #F9C200;
    color: #F9C200;
  }

  > div {
    padding: 8px 16px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 2px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    font-size: 12px;
    font-family: SourceHanSansSC-Regular, SourceHanSansSC;
    font-weight: 400;
    color: #FFFFFF;
    margin-right: 12px;
  }
}

.books {
  padding: 0 20px 20px;

  /deep/ .van-tabs__wrap {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);;
  }

  /deep/ .van-tabs__nav {
    background: transparent;

    .van-tab {
      font-size: 12px;
      font-family: SourceHanSansSC-Regular, SourceHanSansSC;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.6);
      padding: 0;
    }

    .van-tab--active {
      color: #F9C200;
      font-weight: bold;
    }

    .van-tabs__line {
      background: #F9C200;
    }
  }
}

.chapters {
  margin-top: 12px;

  /deep/ .van-cell {
    background: transparent;
    padding: 10px 0;

    .van-cell__title {
      font-size: 14px;
      font-family: SourceHanSansSC-Regular, SourceHanSansSC;
      font-weight: 400;
      color: #FFFFFF;
      text-align: left;
    }
  }

  /deep/ .van-cell::after {
    border: unset;
  }

  /deep/ .van-collapse-item__content {
    background: transparent;
    padding: 12px 0;
  }

  /deep/ .sections-item {
    background: rgba(255, 255, 255, 0.1);

    &__title {
      color: #fff;
    }
  }
}

.wapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
