<template>
  <div :class="'sections-item ' + (isActive ? 'active' : '')" :key="section.sectionId">
    <template v-if="isActive">
      <div class="title">
        <p>{{ index }}、{{ section.sectionName }}</p>
        <p v-if="showQuestionNums">共 {{ section.questions_num }} 题</p>
      </div>

      <van-row class="question-list" gutter="20">
        <van-col
            span="6"
            v-for="(question, questionIndex) in section.questions"
            @click="handleSelectQuestion(question, questionIndex)"
            :key="question.questionID"
        >
          <div :class="computedClass(question, questionIndex)">{{ questionIndex + 1 }}</div>
        </van-col>
      </van-row>
    </template>
    <div class="title" v-else>
      <p class="sections-item__title" >{{ index }}、{{ section.sectionName }}</p>
      <p v-if="showQuestionNums">共 {{ section.questions_num }} 题</p>
      </div>
  </div>
</template>

<script type="application/javascript">
import { mapState } from 'vuex';

export default {
  props: {
    showQuestionNums: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      required: true
    },
    isActive: {
      type: Boolean,
      required: true
    },
    section: {
      required: true
    }
  },
  computed: {
    ...mapState({
      curQuestionId: ({realQuestion}) => realQuestion.curQuestionId
    })
  },

  methods: {
    handleSelectQuestion (question, questionIdx) {
      this.$emit('question-click', {question, questionIdx});
      this.$store.state.realQuestion.curQuestionIdx = questionIdx
      this.$store.state.realQuestion.curQuestionId = question.questionID
      event.stopPropagation();
    },

    computedClass (question, index) {
      let classes = ['question-item'];

      if (this.curQuestionId === question.questionID || localStorage.questionID == question.questionID) {
        classes.push('doing')
      } else {
        const STATUS_COLOR = {
          'false': 'error',
          'true': 'right'
        }
        classes.push(STATUS_COLOR[ question.isRight.toString() ] || 'unknown')
      }

      if(localStorage.questionID) {
        localStorage.questionID = ''
      }
      return classes.join(' ');
    }
  }
}
</script>

<style lang="less" scoped>
.sections-item {
  background: rgba(65, 95, 213, 0.1);
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 12px;
  text-align: left;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;

  .title {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
  }

  &.active {
    background: #415FD5;
    color: #FFFFFF;

    .question-list {
      padding: 8px;

      .question-item {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 1px solid #FFFFFF;
        line-height: 32px;
        text-align: center;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        margin-bottom: 24px;

        &.error {
          background-color: #FF0400;
          border-color: #FF0400;
        }

        &.right {
          background-color: #15C392;
          border-color: #15C392;
        }

        &.doing {
          background-color: #F9C200;
          border-color: #F9C200;
        }

        &.not_done {
          background-color: #415FD5;
        }
      }

      /deep/ .van-col:last-child {
        padding-right: 15px;
      }
    }
  }
}
</style>
