<template>
  <div class="refactor">
    <div class="header">
      <img src="@/assets/img/home/header/logo.png" alt="" @click="goPath('/')"/>

      <div class="btn" @click="goPath('/directory/realQuestion')">
        <van-icon color="white" name="orders-o" />
        <p>主目录</p>
      </div>
    </div>

    <img class="refactor-slogan" src="@/assets/img/refactor/slogan.png" alt=""/>

    <real-question-refactor/>

    <div class="mr-24"/>
    <topic-refactor/>
  </div>
</template>

<script type="application/javascript">
import RealQuestionRefactor from './components/RealQuestionRefactor';
import TopicRefactor from './components/TopicRefactor';
import shareMixin from "@/mixins/shareMixin";

export default {
  mixins: [shareMixin],
  components: {TopicRefactor, RealQuestionRefactor},
  methods: {
    goPath (path) {
      this.$router.push(path)
    }
  },
  mounted () {
    this.initShare()
  }
}
</script>

<style lang="less" scoped>
.refactor {
  height: calc(100vh - 48px);
  overflow: scroll;
  background-image: url("../../assets/img/refactor/background.jpg");
  background-size: 100%;
  filter: blur(0px);
  padding: 24px;

  .header {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;

    > img {
      width: 106px;
      height: 29px;
    }

    .btn {
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;

      padding: 4px 16px;
      border-radius: 20px;
      border: 1px solid rgba(255, 255, 255, 0.5);
      font-size: 14px;
      > p {
        margin-left: 4px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #FFFFFF;
      }
    }
  }

  &-slogan {
    width: 232px;
  }

  .mr-24 {
    height: 24px;
  }
}
</style>
