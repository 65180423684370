<template>
  <div class="card">
    <div class="title">
      <div/>
      <p>真题重组测验</p>
    </div>

    <div class="desc">
      按最新大纲和题型预测重组历年真题，适合冲刺阶段
    </div>

    <div class="form">
      <span @click="levelVisible = true">
        <van-button
            icon="arrow-down"
            type="primary"
            icon-position="right"
            size="small"
           
        >
          {{ levelLabel }}
        </van-button>
      </span>
      <span @click="mathTypeVisible = true">
        <van-button
            icon="arrow-down"
            type="primary"
            icon-position="right"
            size="small"
           
        >
          {{ mathTypeLabel }}
        </van-button>
      </span>
      <span @click="handleVisibleYear">
        <van-button
            icon="arrow-down"
            type="primary"
            icon-position="right" size="small"
           
        >
          已选{{ form.year.length }}年
        </van-button>
      </span>
    </div>

    <div class="tools">
      <div class="create-btn" @click="handleCreatePaper" v-if="!hasPaper">
        开始组卷
      </div>
      <div class="continue-btn" v-else>
        <div @click="handleContinue">继续做题</div>
        <div @click="handleCreatePaper">重新组卷</div>
      </div>
    </div>

    <van-popup v-model="levelVisible" position="bottom">
      <van-picker
          title="选择模式"
          show-toolbar
          :columns="REFACTOR_MODE"
          @confirm="({ value }) => onConfirm(value, 'level')"
          @cancel="levelVisible = false"
          value-key="label"
          :default-index="form.level"
      />
    </van-popup>

    <van-popup v-model="yearVisible" position="bottom" :style="{ height: '308px' }">
      <div class="year-select">
        <div class="header">
          <div @click="handleCancelYearSelect">取消</div>
          <p>选择范围</p>
          <div @click="handleConfirmYearSelect">确定</div>
        </div>

        <div class="years">
          <div
              v-for="year in computedYears"
              :class="activeYears.includes(year) ? 'active' : ''"
              :key="year"
              @click="handleSelectYear(year)"
          >
            {{year}}
          </div>
        </div>
      </div>
    </van-popup>

    <van-popup v-model="mathTypeVisible" position="bottom">
      <van-picker
          title="选择科目"
          show-toolbar
          :columns="MATH_TYPE"
          @confirm="({ value }) => onConfirm(value, 'mathType')"
          @cancel="levelVisible = false"
          value-key="label"
          :default-index="0"
      />
    </van-popup>
  </div>
</template>

<script>
import { MATH_TYPE_ENUM, MATH_TYPE, REFACTOR_MODE, PAPER_TYPE } from '@/consts';
import directoryMixin from '@/mixins/directoryMixin';
import { Toast } from 'vant'

export default {
  mixins: [directoryMixin],
  data () {
    return {
      MATH_TYPE,
      REFACTOR_MODE,
      levelVisible: false,
      yearVisible: false,
      mathTypeVisible: false,

      activeYears: [],
      questionID: null,

      hasPaper: false,
      form: {
        level: '0',
        year: [],
        mathType: MATH_TYPE_ENUM.MATH_ONE
      },

      refactorDirectory: {},
    }
  },
  computed: {
    levelLabel () {
      const ret = REFACTOR_MODE.find(item => item.value === this.form.level);
      return ret.label
    },
    mathTypeLabel () {
      const ret = MATH_TYPE.find(item => item.value === this.form.mathType);
      return ret.label
    },
    computedYears () {
      const yearMap = {
        [MATH_TYPE_ENUM.MATH_ONE]: [2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022],
        [MATH_TYPE_ENUM.MATH_TWO]: [2000, 2001, 2002, 2003, 2004, 2005, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022],
        [MATH_TYPE_ENUM.MATH_THREE]: [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022],
        [MATH_TYPE_ENUM.MATH_ALL]: [2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022]
      }

      return yearMap[this.form.mathType].map(i => i.toString())
    },
    defaultQuestionID () {
      if (!this.refactorDirectory.chapters) return null
      return this.refactorDirectory.chapters[0].sections[0].questions[0].questionID
    }
  },
  methods: {
    onConfirm (value, key) {
      if (key === 'mathType') {
        this.form.year = []
      }

      this.form[ key ] = value
      this[ `${key}Visible` ] = false
    },

    // 开始组卷
    async handleCreatePaper () {
      try {
        const ret = await this.$api.createRealRefactor({
          param: {
            math_type: this.form.mathType,
            level: this.form.level,
            year: this.form.year.join(','),
            catalog_id: 3
          }
        });
        if (!ret.zujuan.maths[0].is_unlock) {
          const toastMap = {
            [MATH_TYPE_ENUM.MATH_ONE]: '请先购买数学一',
            [MATH_TYPE_ENUM.MATH_TWO]: '请先购买数学二',
            [MATH_TYPE_ENUM.MATH_THREE]: '请先购买数学三',
            [MATH_TYPE_ENUM.MATH_ALL]: '请先购买数学一、数学二、数学三',
          }
          Toast({
            message: toastMap[this.form.mathType],
            icon: 'info-o',
          })
          return;
        }
        this.hasPaper = true
        this.refactorDirectory = {...ret.zujuan.maths[0].books[0]} || { chapters: [] }
        this.$router.push({
          path: '/question',
          query: {
            chapterIndex: 0,
            sectionIndex: 0,
            questionIdx: 0,
            questionID: this.defaultQuestionID,
            type: PAPER_TYPE.REFACTOR_REAL,
            level: this.form.level,
            year: this.form.year.length,
            mathType: this.form.mathType
          }
        })
      } catch (err) {
        console.error(err)
      }
    },

    // 继续
    async handleContinue () {
      try {
        const ret = await this.$api.queryQuestionResult({ param: { catalog_id: 3 } });
        this.questionID = ret.questionID || this.defaultQuestionID
        this.$store.commit('realQuestion/updateCurQuestion', { questionID: this.questionID });
        this.$router.push({
          path: '/question',
          query: {
            chapterIndex: ret.chapterIdx || 0,
            sectionIndex: ret.sectionIdx || 0,
            questionIdx: ret.questionIdx || 0,
            questionID: this.questionID,
            type: PAPER_TYPE.REFACTOR_REAL,
            level: this.form.level,
            year: this.form.year.length,
            mathType: this.form.mathType
          }
        })
      } catch (err) {
        console.error(err);
      }
    },

    // 弹出年选择弹框
    handleVisibleYear () {
      this.activeYears = this.form.year
      this.yearVisible = true
    },

    // 选择年
    handleSelectYear (year) {
      if (this.activeYears.includes(year)) {
        this.activeYears = this.activeYears.filter(y => y !== year);
      } else {
        this.activeYears = [...this.activeYears, year];
      }
    },

    // 年选择取消
    handleCancelYearSelect () {
      this.activeYears = []
      this.yearVisible = false
    },

    // 年选择确定
    handleConfirmYearSelect () {
      this.form.year = this.activeYears;
      this.activeYears = [];
      this.yearVisible = false
    },

    // 查询专题组题查询条件及目录
    async queryRefactorDirectory () {
      try {
        const ret = await this.$api.queryRefactorDirectory({
          param: {
            catalog_id: '3'
          }
        });
        if (ret.zujuan === null) {
          this.hasPaper = false
        } else {
          this.hasPaper = true
          this.form = {
            level: ret.level,
            year: ret.year.split(','),
            mathType: ret.math_type
          }
          this.refactorDirectory = {...ret.zujuan.maths[0].books[0]} || { chapters: [] }
        }
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted () {
    this.queryRefactorDirectory();
  }
}
</script>

<style lang="less" scoped>
@import './index.less';

.form {
  padding: 0 20px 20px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;

  /deep/ .van-button--primary {
    background: rgba(255, 255, 255, 0.2);
    border: unset;
  }
}

.tools {
  .create-btn {
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: linear-gradient(90deg, #3D58D0 0%, #6C3CFF 100%);
    font-size: 14px;
    font-family: SourceHanSansSC-Bold, SourceHanSansSC;
    font-weight: bold;
    color: #FFFFFF;
    border-radius: 0px 0px 12px 12px;
  }

  .continue-btn {
    height: 40px;
    line-height: 40px;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;

    > div {
      text-align: center;
      width: 50%;
      font-size: 14px;
      font-family: SourceHanSansSC-Bold, SourceHanSansSC;
      font-weight: bold;
    }

    > div:first-child {
      background: linear-gradient(135deg, #F9C200 0%, #FF9051 100%);
      border-radius: 0px 0px 0px 12px;
    }

    > div:last-child {
      background: linear-gradient(90deg, #3D58D0 0%, #6C3CFF 100%);
      border-radius: 0px 0px 12px 0px;
    }
  }
}

.year-select {
  .header {
    height: 44px;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    > div {
      padding: 0 16px;
    }
    > div:first-child {
      color: #969799;
    }
    > div:last-child {
      color: #576b95
    }
    > p {
      max-width: 50%;
      font-weight: 500;
      font-size: 0.5rem;
      line-height: 0.625rem;
      text-align: center;
    }
  }
  .years {
    padding: 24px 16px;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    flex-wrap: wrap;
    > div {
      width: 30%;
      height: 40px;
      line-height: 40px;
      background: #FFFFFF;
      border-radius: 2px;
      border: 1px solid #B9BFD3;
      margin-bottom: 12px;
      font-weight: 800;
      color: #9498AA;
    }
    .active {
      color: #415FD5;
      background: rgba(65, 95, 213, 0.1);
      border: 1px solid #415FD5;
    }
  }
}

</style>
